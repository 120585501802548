@use 'variables' as v;

/* BORDER */
.border,
.inner-border {
  border: 1px solid rgba(148, 148, 148, 0.275);
  background: black;
}

.inner-border {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  margin: 3px;
}

/* TEXT-ALIGN */
.text-align--left {
  text-align: left;

  @media screen and (max-width: 800px) {
    text-align: center;
  }
}

.text-align--center {
  text-align: center;
}

.text-align--right {
  text-align: right;

  @media screen and (max-width: 800px) {
    text-align: center;
  }
}

/* TEXT-COLOR */
.txt-grey {
  color: rgba(148, 148, 148, 0.678);
}

.txt-transparent-grey {
  color: rgba(148, 148, 148, 0.361) !important;
}

.txt-lightgrey {
  color: #c7c7c7;
}

.txt-white {
  color: rgba(255, 255, 255, 0.78);
}

.txt-green {
  color: rgba(184, 237, 48, 0.792) !important;
}

/* FONT-SIZE */

.fs-10 {
  font-size: 1vw;

  @media screen and (max-width: 1170px) {
    font-size: 12px;
  }

  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
}

.fs-11 {
  font-size: 1.1vw;

  @media screen and (max-width: 1170px) {
    font-size: 13px;
  }

  @media screen and (max-width: 640px) {
    font-size: 11px;
  }
}

.fs-12 {
  font-size: 1.2vw;

  @media screen and (max-width: 1170px) {
    font-size: 14px;
  }

  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
}

.fs-14 {
  font-size: 1.4vw;

  @media screen and (max-width: 1170px) {
    font-size: 15px;
  }

  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
}

.fs-15 {
  font-size: 1.5vw;

  @media screen and (max-width: 1170px) {
    font-size: 16px;
  }

  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
}

.fs-18 {
  font-size: 1.8vw;

  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
}

.fs-20 {
  font-size: 2.0vw;

  @media screen and (max-width: 1000px) {
    font-size: 20px;
  }
}

.fs-24 {
  font-size: 2.4vw;

  @media screen and (max-width: 950px) {
    font-size: 20px;
  }
}

.fs-30 {
  font-size: 3vw;


  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
}

.fs-50 {
  font-size: 5vw;


  @media screen and (max-width: 700px) {
    font-size: 24px;
  }
}

/* TEXT DECORATION */
.uc {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

/* BACKGROUND */
.bg-black {
  background: rgb(12, 12, 12);
}

.bg-grey {
  background: rgb(24, 24, 24);
}

.bg-dots {
  background: rgb(12, 12, 12) url(../media/dots2.png);
}

.bg-trans {
  background-color: transparent;
}

/* PADDING MARGIN WIDTH */
.pd--4 {
  padding: 4vw;
}

.pd--5 {
  padding: 5vw;
}

.mg-b-40 {
  margin-bottom: 40px;
}

.mg-t-40 {
  margin-top: 40px;
}

.mg-b-10 {
  margin-bottom: 10px;
}

.mg-r-10 {
  margin-right: 10px;
}

.mg-l-10 {
  margin-left: 10px;
}

.w-100 {
 width: 100%;
}

/* BUTTONS */

button {
  cursor: pointer;
}

.button-primary {
  height: 60px;
  background: black;
  border: 1px solid rgba(148, 148, 148, 0.275);
  padding: 2px;
  width: 200px;
  transition: 500ms;

  &_container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 500ms;

    &_text {
      color: rgba(184, 237, 48, 0.792);
      text-transform: uppercase;
      font-weight: 600;
      text-decoration: none;
      transition: 500ms;
    }
  }
}

/* PAGE */

.page {
  min-height: calc(100vh - v.$footer);
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.section,
.video-bg-wrapper {
  @media screen and (max-width: 640px) {
    padding-bottom: 60px;
  }
}

/* ====================== [ Start progress-wrap ] ====================== */
.progress-wrap {
  position: fixed;
  bottom: 50px;
  right: 30px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;

  @media screen and (max-width: 641px) {
    right: 20px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.progress-wrap::after {
  position: absolute;
  background: url(../media/arrow-up.png) no-repeat center;
  font-family: 'Font Awesome 5 Free';
  content: ' ';
  text-align: center;
  line-height: 44px;
  font-size: 13px;
  font-weight: 900;
  left: 0;
  top: 0;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: v.$green;
  stroke-width: 4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}
/* ====================== [ End progress-wrap ] ====================== */

/* ====================== [ Start contact-buttons-aside ] ====================== */

@keyframes appearFromRight {
  0% {
    right: 0;
    opacity: 0;
  }
  100% {
    right: 30px;
    opacity: 1;
  }
}

@keyframes pulsation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.contact-buttons-aside {
  position: fixed;
  z-index: 50;
  bottom: 115px;
  right: 30px;
  opacity: 1;
  animation: appearFromRight 1s;

  @media screen and (max-width: 641px) {
    right: 20px;
  }

  &_link {
    &_img {
      height: 46px;
      width: 46px;
      transition: 500ms;
      animation: pulsation 2s infinite ease-in-out;
    }

    &_img.viber {
      background: url(./../media/viber.png) no-repeat;
      background-size: contain;
      background-position: center;
      margin-bottom: 20px;
    }

    &_img.tel {
      background: url(./../media/tel.png) no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}

/* ====================== [ End contact-buttons-aside ] ====================== */

/* ====================== [ Sliding Border ] ====================== */

@mixin btn-border-slide($hoverColor, $borderWidth, $time) {
  $easing: cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    height: $borderWidth;
    width: 100%;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &:hover {
    & > * {
      & > * {
        &:before,
        &:after {
          transform: translate3d(0, 0, 0);
        }

        & > * {
          &:before,
          &:after {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }

  & > * {

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: $borderWidth;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    & > * {
      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        z-index: 9;
        height: $borderWidth;
        width: 100%;
        background-color: $hoverColor;
      }

      &:before {
        top: 0;
        transform: translate3d(-105%, 0, 0);
        transition: transform $time $easing;
      }

      &:after {
        bottom: 0;
        transform: translate3d(105%, 0, 0);
        transition: transform $time $easing;
      }

      & > * {
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 0;
          z-index: 9;
          height: 100%;
          width: $borderWidth;
          background-color: $hoverColor;
        }

        &:before {
          left: 0;
          transform: translate3d(0, 105%, 0);
          transition: transform $time $easing;
        }

        &:after {
          right: 0;
          transform: translate3d(0, -105%, 0);
          transition: transform $time $easing;
        }
      }
    }
  }
}

/*
  Add sliding-border class and two span tags inside to make it function:
  <div className="sliding-border">
    <span>
      <span>
      </span>
    </span>
  </div>
*/
.sliding-border {
  @include btn-border-slide(v.$green, 2px, 1s);
}

/* ====================== [ Sliding Border ] ====================== */

.green-shadow-hover:hover {
  transition: 500ms;
  -webkit-box-shadow: 0px 0px 20px 1px rgba(184,237,48,0.4);
  -moz-box-shadow: 0px 0px 20px 1px rgba(184,237,48,0.4);
  box-shadow: 0px 0px 20px 1px rgba(184,237,48,0.4);
}

.section {
  display: flex;
  z-index: 2;

  &_container {
    height: 100%;
    display: flex;
  }
}

.title {
  width: 100%;
  margin-bottom: 20px;
  color: white;
}

.subtitle {
  width: 100%;
  margin-bottom: 15px;
  font-weight: 400;
}
