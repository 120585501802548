@use 'variables' as v;

.services-page {
  margin-top: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;

  &_nav {
    list-style: none;
    width: 100vw;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    gap: 20px;

    &_nav-item {
      min-height: 55px;
      padding: 10px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .border:hover,
    .inner-border:hover {
      transition: 400ms;
      color: v.$green;
    }
  }
}

.service-item-page {
  width: 100vw;
  margin-top: 90px;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    margin-top: 80px;
  }

  &_media {
    width: 50%;
    height: calc(100vh - 90px);
    object-fit: cover;

    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  &_content {
    width: 50%;
    padding: 40px;

    @media screen and (max-width: 900px) {
      width: 100%;
      padding: 20px;
    }

    .title {
      margin-bottom: 30px;
    }

    &_text {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 20px;

      ul {
        list-style: none;
      }

      &_link {
        display: inline-block;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 3px;
          bottom: -2px;
          left: 0;
          background: rgb(12, 12, 12);
          background: linear-gradient(90deg, rgba(12,12,12,1) 7%, rgba(237,237,237,1) 100%);
          transform-origin: bottom right;
          transition: transform 0.6s ease-out;
        }

        &:hover:after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }
}
