@use 'variables' as v;

@import url(./_variables.scss);
@import url(./common.scss);

@import url(./header.scss);
@import url(./footer.scss);

@import url(./homepage.scss);
@import url(./feedback.scss);
@import url(./contacts.scss);
@import url(./ordering.scss);
@import url(./services.scss);
@import url(./aboutUs.scss);
@import url(./questions.scss);

@import 'https://fonts.googleapis.com/css?family=Montserrat:300, 400, 700&display=swap';

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
  margin: 0;
  background: black url(../media/tech_pro.png) repeat;
  background-size: 100% auto;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}
