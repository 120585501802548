@use 'variables' as v;

.section_ordering {
  flex-direction: column;
  align-items: center;

  &_content {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 30px;
    padding: 10px 0;

    &_text-container {
      height: 100%;

      ul {
        margin: 30px 0;
        padding: 40px;
        border-top: 1px solid v.$borderGrey;
        border-bottom: 1px solid v.$borderGrey;

        p {
          margin-bottom: 10px;
        }
      }

      & > * {
        margin-bottom: 20px;
      }
    }
  }
}
