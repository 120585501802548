.section_about-us {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  .logo {
    width: 25vw;
  }

  &_container {
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    padding: 5vw;
    gap: 30px;

    @media screen and (max-width: 640px) {
      flex-direction: column-reverse;
      align-items: center;
      padding: 30px;
    }

    @media screen and (max-width: 450px) {
      padding: 30px 15px;
      width: 100%;
    }

    &_text-container {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 640px) {
        align-items: center;
      }

      .subtitle {
        max-width: 300px;

        @media screen and (max-width: 640px) {
          text-align: center;
        }

        @media screen and (max-width: 450px) {
          font-size: 12px;
        }
      }

      .button-primary {
        margin-top: 50px;

        @media screen and (max-width: 640px) {
          margin-top: 20px;
        }
      }
    }
  }

  &_text {
    width: 80%;
    margin-top: 3vw;
    text-align: justify;
  }
}

.about-us-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page_header {
    width: 100%;
    height: 100vh;
    margin-bottom: 80px;
    position: relative;

    &_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    &_content {
      z-index: 3;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.569);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 80px 10% 10% 10%;
    }
  }

  &_services-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    list-style: none;
    margin: 20px 10px 40px;

    &_item {
      &_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        text-align: center;
      }
    }
  }

  &_main-text {
    width: 85vw;
    text-align: center;
  }

  &_contact-us {
    width: 70vw;
    margin: 50px 0 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .button-primary {
      margin-top: 30px;
    }
  }
}
