@use 'variables' as v;

.section-qa {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 850px) {
    gap: 0;
  }

  &_content {
    display: flex;
    justify-content: center;
    gap: 3vw;
    

    &_img {
      max-height: 500px;
    
      @media screen and (max-width: 850px) {
        display: none;
      }
    }

    &_questions {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      div:not(.inner-border) {
        width: 100%;
      }

      &_question {
        display: flex;
        gap: 15px;
        padding: 40px;

        @media screen and (max-width: 640px) {
          padding: 20px;
        }
  
        .txt-white { /* question title */
          margin-bottom: 15px;
        }
      }
    }
  }
}
